import {History} from 'history';
import {AnyAction} from 'redux';
import {AxiosAction, AxiosSuccessAction} from '../middleware/axios';
import {Customer, CustomerType, Module} from './state';

const GET_CUSTOMERS = 'CUSTOMERS.GET_ALL';
const GET_CUSTOMER = 'CUSTOMERS.GET_ONE';
const PUT_CUSTOMER = 'CUSTOMERS.PUT';
const CREATE_CUSTOMER = 'CUSTOMERS.CREATE';
const CREATE_CUSTOMER_SUPPORT_USER = 'CUSTOMERS.SUPPORT_USER.CREATE';
const GET_CUSTOMERS_SUCCESS = GET_CUSTOMERS + '_SUCCESS';
const GET_CUSTOMER_SUCCESS = GET_CUSTOMER + '_SUCCESS';
const PUT_CUSTOMER_SUCCESS = PUT_CUSTOMER + '_SUCCESS';
const CREATE_CUSTOMER_SUCCESS = CREATE_CUSTOMER + '_SUCCESS';
const CREATE_CUSTOMER_SUPPORT_USER_SUCCESS = CREATE_CUSTOMER_SUPPORT_USER + '_SUCCESS';

export interface CustomerDto {
  customerId: string;
  name: string;
  automatedMonitoringEnabled: boolean;
  workManagementEnabled: boolean;
  type: CustomerType;
  isTemplate: boolean;
  customerTemplateId?: string[];
  samlMetadataUrl?: string;
}

export type CreateCustomerDto = Omit<CustomerDto, 'customerId'> & {
  location: {name: string},
  user: {email: string, name: string},
  samlMetadataUrl?: string
};

export interface CreateCustomerSupportResponse {
  signInUrl: string;
}

export interface GetCustomersAction extends AxiosAction {
  type: typeof GET_CUSTOMERS;
}

export interface GetCustomersSuccessAction extends AxiosSuccessAction<CustomerDto[]> {
  type: typeof GET_CUSTOMERS_SUCCESS;
}

export interface GetCustomerAction extends AxiosAction {
  type: typeof GET_CUSTOMER;
}

export interface GetCustomerSuccessAction extends AxiosSuccessAction<CustomerDto> {
  type: typeof GET_CUSTOMER_SUCCESS;
}

export interface PutCustomerAction extends AxiosAction {
  type: typeof PUT_CUSTOMER;
}

export interface PutCustomerSuccessAction extends AxiosSuccessAction<CustomerDto> {
  type: typeof PUT_CUSTOMER_SUCCESS;
}

export interface CreateCustomerAction extends AxiosAction {
  type: typeof CREATE_CUSTOMER;
}

export interface CreateCustomerSuccessAction extends AxiosSuccessAction<CustomerDto> {
  type: typeof CREATE_CUSTOMER_SUCCESS;
}

export interface CreateCustomerSupportUserSuccessAction extends AxiosSuccessAction<CreateCustomerSupportResponse> {
  type: typeof CREATE_CUSTOMER_SUPPORT_USER_SUCCESS;
}

export function getCustomers(): GetCustomersAction {
  return {
    payload: {
      client: 'backendAdmin',
      request: {
        url: '/customers'
      }
    },
    type: GET_CUSTOMERS
  };
}

export function getCustomer(id: string): GetCustomerAction {
  return {
    payload: {
      client: 'backendAdmin',
      request: {
        url: `/customers/${id}`
      }
    },
    type: GET_CUSTOMER
  };
}

export function putCustomer(customer: Customer & {customerTemplateId: string}, history: History): PutCustomerAction {
  const data: CustomerDto = {
    automatedMonitoringEnabled: customer.modules.includes(Module.AUTOMATED_MONITORING),
    customerId: customer.customerId,
    isTemplate: customer.isTemplate,
    name: customer.name.trim(),
    samlMetadataUrl: customer.samlMetadataUrl,
    type: customer.type,
    workManagementEnabled: customer.modules.includes(Module.WORK_MANAGEMENT)
  };

  if (customer.customerTemplateId) {
    data.customerTemplateId = [customer.customerTemplateId];
  }

  return {
    onSuccess: () => dispatch => dispatch(() => history.push('/')),
    payload: {
      client: 'backendAdmin',
      request: {
        data,
        method: 'PUT',
        url: `/customers/${customer.customerId}`
      }
    },
    type: PUT_CUSTOMER
  };
}

export function createCustomer(
    customer: Omit<Customer, 'customerId'> & {customerTemplateId: string, userName: string, email: string},
    history: History
): CreateCustomerAction {
  const data: CreateCustomerDto = {
    automatedMonitoringEnabled: customer.modules.includes(Module.AUTOMATED_MONITORING),
    isTemplate: customer.isTemplate,
    location: {
      name: customer.name.trim()
    },
    name: customer.name.trim(),
    samlMetadataUrl: customer.samlMetadataUrl,
    type: customer.type,
    user: {
      email: customer.email.trim(),
      name: customer.userName.trim()
    },
    workManagementEnabled: customer.modules.includes(Module.WORK_MANAGEMENT)
  };

  if (customer.customerTemplateId) {
    data.customerTemplateId = [customer.customerTemplateId];
  }

  return {
    onSuccess: () => dispatch => dispatch(() => history.push('/customers/')),
    payload: {
      client: 'backendAdmin',
      request: {
        data,
        method: 'POST',
        url: '/customers/register'
      }
    },
    type: CREATE_CUSTOMER
  };
}

export function createCustomerSupportUser(
  customerId: string
): AxiosAction {
  return {
    payload: {
      client: 'backendAdmin',
      request: {
        method: 'POST',
        url: `/customers/${customerId}/supportUser`
      }
    },
    type: CREATE_CUSTOMER_SUPPORT_USER
  };
}

export function isGetCustomersSuccess(action: AnyAction): action is GetCustomersSuccessAction {
  return action.type === GET_CUSTOMERS_SUCCESS;
}

export function isGetCustomerSuccess(action: AnyAction): action is GetCustomerSuccessAction {
  return action.type === GET_CUSTOMER_SUCCESS;
}

export function isPutCustomerSuccess(action: AnyAction): action is PutCustomerSuccessAction {
  return action.type === PUT_CUSTOMER_SUCCESS;
}

export function isCreateCustomerSuccess(action: AnyAction): action is CreateCustomerSuccessAction {
  return action.type === CREATE_CUSTOMER_SUCCESS;
}

export function isCreateCustomerSupportUserSuccess(
  action: AnyAction
): action is AxiosSuccessAction<CreateCustomerSupportResponse> {
  return action.type === CREATE_CUSTOMER_SUPPORT_USER_SUCCESS;
}
