import Face from '@material-ui/icons/Face';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {AnyAction} from 'redux';
import FormPage from '../components/formPage/FormPage';
import {AxiosAction, AxiosDispatch} from '../middleware/axios';
import {createSnackbar} from '../notifications/actions';
import {createCustomer, getCustomers, isCreateCustomerSuccess} from './actions';
import CustomerForm, {SubmitProps} from './form/CustomerForm';
import messages from './translations';

export interface Props extends RouteComponentProps {
  dispatch: AxiosDispatch;
}

export const NewCustomer = (props: Props) => {
  const {dispatch, history} = props;
  const {formatMessage} = useIntl();

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  const onSubmit = ({
    isTemplate,
    modules,
    name,
    customerTemplateId,
    samlMetadataUrl,
    type,
    email,
    userName
  }: SubmitProps): Promise<AnyAction> => {
    return dispatch(createCustomer({
      customerTemplateId,
      email,
      isTemplate,
      modules,
      name,
      samlMetadataUrl,
      type,
      userName
    }, history))
        .then((action: AxiosAction) => {
          if (isCreateCustomerSuccess(action)) {
            dispatch(createSnackbar({
              message: formatMessage(messages['customers.create.success']),
              options: {
                variant: 'success'
              }
            }));
          } else {
            dispatch(createSnackbar({
              message: formatMessage(messages['customers.create.error']),
              options: {
                variant: 'error'
              }
            }));
          }

          return action;
        });
  };

  return (
      <FormPage pageTitle={formatMessage(messages['customers.addNewCustomer'])} pageIcon={<Face/>}>
        <>
          <CustomerForm customerId={null} onSubmit={onSubmit} />
        </>
      </FormPage>
  );
};

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({
  dispatch
});

export default connect(undefined, mapDispatchToProps)(NewCustomer);
