// The react-app-polyfill imports MUST be the first imports in the file
/* tslint:disable:ordered-imports */

import {MuiThemeProvider} from '@material-ui/core';
import 'date-time-format-timezone';
import 'intl-pluralrules';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import 'typeface-roboto';
import App from './App';
import {updateTokenAction} from './auth/actions';
import * as serviceWorker from './serviceWorker';
import {store} from './store';
import {officeTheme} from './theme';
import {keycloak} from './keycloak';
import { startRUM } from './datadog-rum';
import config from './config';

keycloak.init({ onLoad: 'login-required' }).success((authenticated) => {
  if (authenticated && keycloak.token) {
    startRUM(config);
    store.dispatch(updateTokenAction(
      keycloak.logout.bind(keycloak),
      keycloak.updateToken.bind(keycloak),
      () => keycloak.token
    ));

    ReactDOM.render((
        <Provider store={store}>
          <MuiThemeProvider theme={officeTheme}>
            <App/>
          </MuiThemeProvider>
        </Provider>
      ),
      document.getElementById('root')
    );

  } else {
    keycloak.login();
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
