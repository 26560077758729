import { datadogRum } from '@datadog/browser-rum';

export function startRUM(config: any) {
  if (config.datadog.enabled) {
    const { applicationId, clientToken, site, service, defaultPrivacyLevel } = config.datadog;
    const dd = {
      allowedTracingUrls: [
        /https:\/\/(?!api\.appcues\.net|auth-staging|auth\.checkit|checkit-keycloak|.*\.auth|.*\.cognito-auth).*\.(elektron-dev\.com|checkit-test\.net|checkit\.net)/
      ],
      applicationId,
      clientToken,
      defaultPrivacyLevel,
      env: config.environment,
      service,
      sessionReplaySampleRate: 100,
      sessionSampleRate: 100,
      site,
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      version: config.version
    };

    datadogRum.init(dd);
  }
}
