const config = {
  addFeaturesRole: process.env.REACT_APP_ADD_FEATURES_ROLE || 'local-customer-service-add-features',
  auth: {
    accessTokenValidity: Number(process.env.REACT_APP_ACCESS_TOKEN_MIN_VALIDITY_SECS) || 30,
    clientId: process.env.REACT_APP_OAUTH2_CLIENT_ID,
    cognitoBaseDomain: process.env.REACT_APP_COGNITO_BASE_DOMAIN,
    realm: process.env.REACT_APP_OAUTH2_REALM,
    url: process.env.REACT_APP_OAUTH2_API_URL
  },
  backend: {
    url: process.env.REACT_APP_API_URL
  },
  controlCentre: {
    url: process.env.REACT_APP_FRONTEND_HOME_URI
  },
  core: {
    url: process.env.REACT_APP_CORE_API_URL
  },
  customerProfile: {
    url: process.env.REACT_APP_CUSTOMER_PROFILE_API_URL
  },
  datadog: {
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask-user-input',
    enabled: process.env.REACT_APP_DATADOG_RUM_ENABLED === 'true',
    service: 'office-ui',
    site: 'datadoghq.com'
  },
  environment: process.env.REACT_APP_CHECKIT_ENV || 'local',
  features: {
    customerRelationships: process.env.REACT_APP_FT_CUSTOMER_RELATIONSHIPS === 'true'
  },
  gitBranch: process.env.REACT_APP_BRANCH_NAME || 'branch-name',
  gitCommit: process.env.REACT_APP_GIT_COMMIT || 'commit-id',
  help: {
    url: process.env.REACT_APP_HELP_URL || 'https://www.checkit.net/help'
  },
  linkCustomerRole: process.env.REACT_APP_LINK_CUSTOMER_ROLE || 'local-customer-service-link-customer',
  ssoFeatureEnabled: process.env.REACT_APP_FT_SSO === 'true',
  updateServer: {
    url: process.env.REACT_APP_UPDATE_SERVER_API_URL
  },
  version: process.env.REACT_APP_VERSION,
  workManagement: {
    url: process.env.REACT_APP_WORK_MANAGEMENT_API_URL
  }
};

export default config;
